<template>
    
    <v-container>
        <v-row>
             <v-col cols="6" lg="12" md="12">
             <v-slide-x-transition appear>
            <div id="bgPass"></div>
            </v-slide-x-transition>
           </v-col>
            <v-slide-y-transition appear>
            <v-col cols="12" lg="12" md="12" class="mt-16">
                <v-card
                color="#3F3D56"
                max-width="374"
                class="card-top mx-auto rounded-lg  rounded-lg"
                elevation="4"
                
                >
                 <v-form  @submit.prevent="enviarPass(usuario)">
                <v-container>
                  <v-row>
                    <v-col
                        cols="12"
                        lg="12"
                        md="10"
                        sm="10"
                    >
                    <h3 class="white--text text-center card-title">Recuperar Contraseña</h3>
                        <v-text-field
                            label="Correo"
                            prepend-inner-icon="mdi-account"
                            v-model="usuario"
                            :rules="emailRules"
                            required
                            dark
                        ></v-text-field>
                        <div class="text-center">
                            <v-btn
                            class="rounded-pill text-capitalize"
                            type="submit"
                            value="enviar"
                            color="#43A047"
                            v-on:click.prevent="enviarPass"
                            dark
                            >
                            Enviar </v-btn>
                        </div>
                    </v-col>
                </v-row>
                </v-container>
            </v-form>
        </v-card>
            </v-col>   
         </v-slide-y-transition> 
        </v-row>  
          <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>      
    </v-container>            
   
</template>
<script>
import Vue from 'vue';
import 'vue-toast-notification/dist/theme-sugar.css';
export default {
     data: () => ({
         overlay: false,
        //   loading: false,
         emailRules: [
        v => !!v || 'Correo es requerido',
        v => /.+@.+/.test(v) || 'Correo debe ser valido',
      ],
      
          usuario:'',
      
      wrongCredentials: false,
      wrongCredentialsMessage:'',
      submited: false,
     }),

    methods: {
        
        enviarPass: async function(){
             
        //    if( this.usuario === ''){ 
        //        this.toasterMessageResponse('El campo correo es requerido para continuar', 'error', 5000, true);
        // return 0;
        //    }  
         if( !this.usuario){ 
               this.toasterMessageResponse('El campo correo es requerido para continuar', 'error', 5000, true);
           
           }  else if(!this.validEmail(this.usuario)){
               this.toasterMessageResponse('El correo debe ser válido', 'error');
               return 0;
           }
          
        //    this.overlay = true;
            
            await this.$http.get(this.$keys('RECUPERAR_CONTRASENA') + '/' + this.usuario)
            .then((response) =>{
                 setTimeout(() => {
                if(response.status === 201){
                    console.log(response);
                    this.toasterMessageResponse(response.data.mensaje,'success', 'error', 5000, true);
                    location.href ="/iniciarsesion";
                }
                 },1000);
                // this.$router.push('/iniciarsesion')
                this.overlay = true;
            })
                
            .catch( (error) => {
                let errorResponse = JSON.parse(error.response.data.mensaje)
                this.wrongCredentials = true;
                this.wrongCredentialsMessage = errorResponse.error;
                this.overlay = true;
                /*this.flashMessage.show({status: 'error', title: 'Mi aplicación', message:'Los datos no son válidos' +error})
                document.from.reset();*/
            //     if ( this.usuario.usuario === ''){
            //     alert ("El campo esta vacío")
            //     return false
            // }else{
            //     // this.msgerror = msg;
            // }
      });

        },
         validEmail:function(emailR) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(emailR);
    },
        
    toasterMessageResponse : function (success, type ){
      Vue.$toast.open({
        message: success,
        type: type,
        duration: 5000,
        pauseOnHover: true,
        position: 'top'
      });
    }
    },
  
}
</script>
<style>
#bgPass{
    background:url("../assets/img/pass.png"); 
    position: fixed;
  left: 0;
  top: 0;
  width: 70%;
  height: 70%;
  background-size: cover;
}
@media (max-width: 750px){
   #bgPass{
       /* display: none; */
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: contain;
   }
}
.fullwith{
    margin-top: -20px; 
}
.card-top{
    top: 80px;
    background: #003791;
}
</style>